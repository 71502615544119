import React, { useEffect, useMemo, useState } from 'react';
import { Card, ContainerLayout } from 'common';
import { Box, Grid, makeStyles, Tabs, Tab } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { ResponseBar } from './ResponseBar';
import { UserResponses } from './UserResponses';
import { PromptInformation } from './PromptInformation';
import { DetailsFilters } from './DetailsFilters';
import { useAppSelector } from 'store';
import { PromptDetailsFilters } from 'pages/GroovVoice/GroovVoice';
import { PromptCTATypes } from 'pages/PromptBuilder';

export const PromptDetails: React.FC = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();

  const promptDetails = useAppSelector((state) => state.groovVoice.currentSelectedPromptDetails);
  const prompts = useAppSelector((state) => state.groovVoice.prompts);

  const prompt = useMemo(() => prompts.find((p) => p.promptId.toString() === id), [id, prompts]);

  const filteredPromptResponsesDetails = promptDetails?.responses;

  const isOpenTextType = prompt?.properties?.prompt_cta_type === PromptCTATypes.openEndedText;
  const [value, setValue] = useState(isOpenTextType ? 'responses' : 'summary');

  const handleChange = (_: unknown, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!prompt) {
    return <></>;
  }
  return (
    <ContainerLayout>
      <Card className={classes.card}>
        <Grid container spacing={10}>
          <Grid item xs={12} lg={8}>
            <Box className={classes.root}>
              <PromptInformation prompt={prompt} promptDetails={promptDetails} />
              <Box my={6}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  {!isOpenTextType && <Tab label="Summary" value="summary" />}
                  <Tab label="Responses" value="responses" />
                </Tabs>
                <Box hidden={value !== 'summary'} my={6}>
                  <Card className={classes.responseCard}>
                    {promptDetails?.prompt.actions.map((data, index) => {
                      const count =
                        filteredPromptResponsesDetails?.filter((c) => c.text === data.action)
                          ?.length ?? 0;

                      const percentage = filteredPromptResponsesDetails?.length
                        ? (count / filteredPromptResponsesDetails?.length) * 100
                        : 0;
                      return (
                        <ResponseBar
                          key={`${data.action}-${index}`}
                          label={data.action}
                          percentage={percentage}
                          responseCount={count}
                          index={index}
                        />
                      );
                    })}
                  </Card>
                </Box>
                <Box hidden={value !== 'responses'} my={6}>
                  <Card className={classes.responseCard}>
                    <UserResponses
                      responseList={filteredPromptResponsesDetails ?? []}
                      promptCTAType={prompt?.properties?.prompt_cta_type as PromptCTATypes}
                      isConfidential={prompt?.confidential}
                    />
                  </Card>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box mt="40%">
              <DetailsFilters {...mapResponsePropertiesToOptions(promptDetails?.filters)} />
            </Box>
          </Grid>
        </Grid>
      </Card>
    </ContainerLayout>
  );
};

const mapResponsePropertiesToOptions = (filters?: PromptDetailsFilters) => {
  const managerOptions = filters?.managers.filter((m) => Boolean(m.id));
  const officeLocationOptions = filters?.officeLocations.filter((o) => Boolean(o));
  const departmentOptions = filters?.departments.filter((o) => Boolean(o));
  const jobTitleOptions = filters?.jobTitles.filter((o) => Boolean(o));

  return {
    managerOptions,
    officeLocationOptions,
    departmentOptions,
    jobTitleOptions,
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(4),
    flex: 1,
  },
  card: {
    flex: 1,
    marginBottom: theme.spacing(12),
  },
  responseCard: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(12),
  },
}));
