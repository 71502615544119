import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Theme,
  Typography,
  createStyles,
  makeStyles,
  Button as MUIButton,
  Box,
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
  Switch,
  colors,
} from '@material-ui/core';
import { Button, Card, ContainerLayout, defaultTextInputStyle, Input } from 'common';
import { AnimatedSpinner } from 'common/components/AnimatedSpinner';
import { FormikProps, useFormik } from 'formik';
import { promptBuilder } from 'prompts/prompts';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormLabelWithHelperText } from 'pages/ContextualNudge/components';
import { CTASection, CTAType } from './CTASection';
import { RichTextEditor } from 'common/components/RichTextEditor/RichTextEditor';
import { CsvFileUpload, OrgUser } from 'common/components/FileUpload/FileUpload';
import { useHistory } from 'react-router-dom';
import {
  PromptBuilderFormProps,
  PromptBuilderFormTypes,
  PromptCTATypes,
} from './PromptBuilder.types';
import { useAppDispatch } from 'store';
import { clearLocalPrompt, setCurrentLocalPrompt } from './promptBuilder.slice';
import * as yup from 'yup';
import { SearchAndSelect } from 'pages/GroovVoice/Components/Common/SearchAndSelect';
import { SelectOrCreateOptionType } from 'pages/GroovVoice/GroovVoice';
import usePromptAttributes from './usePromptAttributes';
import { RoutePath } from 'types/routes';

const MAX_BUTTON_TEXT_LENGTH = 75;
const MAX_TEXT_LENGTH = 1500;

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .matches(/^[a-zA-Z0-9 ]*$/, 'Name can only contain letters, numbers and spaces')
    .required('Please enter a Name'),
  promptDateTime: yup.date().required('Please select a Date and Time'),
  categoryId: yup.string().required('Please select a category'),
  body: yup
    .string()
    .max(MAX_TEXT_LENGTH, 'Prompt message is too long')
    .required('Please enter a message'),
  ctaType: yup.string().required('Please select a Response type'),
  userList: yup.array().min(1, 'Please upload a User list').required('Please upload a User list'),
  responseToOpenText: yup.string().when(['ctaType'], {
    is: (value: string) => {
      return value === PromptCTATypes.customAndOpenEndedText;
    },
    then: () => yup.string().required('Please enter a message'),
  }),
  ctaValue: yup.array().when(['ctaType'], {
    is: (value: string) => {
      return (
        value === PromptCTATypes.custom ||
        value === PromptCTATypes.sixPtScale ||
        value === PromptCTATypes.customAndOpenEndedText ||
        value === PromptCTATypes.sixPtScaleAndOpenEndedText
      );
    },
    then: () =>
      yup
        .array()
        .min(1, 'At least one Action is required for the Action Buttons Response type')
        .of(
          yup.object().shape({
            name: yup
              .string()
              .max(MAX_BUTTON_TEXT_LENGTH, 'Action button message is too long')
              .required('Action button text is required'),
            description: yup
              .string()
              .max(MAX_TEXT_LENGTH, 'Action response message is too long')
              .required('Action response text is required'),
          })
        ),
  }),
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      justifyContent: 'space-between',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: theme.spacing(9),
    },
    manageNudgeCompany: {
      marginTop: theme.spacing(3),
    },
    createPrompt: {
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(6),
      minWidth: theme.spacing(42),
    },
    cancelPrompt: {
      marginRight: theme.spacing(4),
      minWidth: theme.spacing(42),
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(6),
      textTransform: 'none',
    },
    flex: {
      marginTop: theme.spacing(24),
      display: 'flex',
    },
    radio: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    select: {
      marginTop: theme.spacing(4),
      borderRadius: theme.spacing(3),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      backgroundColor: 'white',
    },
    switchTrack: {
      backgroundColor: colors.red[500],
    },
  })
);

export function PromptBuilderForm({
  title,
  initialValues,
  editMode = false,
}: Readonly<PromptBuilderFormProps>) {
  const dispatch = useAppDispatch();
  const submitButtonContent = useMemo(
    () => (editMode ? promptBuilder.promptUpdate : promptBuilder.promptCreate),
    [editMode]
  );
  const [users, setUsers] = useState<OrgUser[] | undefined>(undefined);
  const classes = useStyles();
  const tagStyles = makeStyles(defaultTextInputStyle)();
  const { push } = useHistory();
  const { tags, categoryOptions, loading: attributesLoading } = usePromptAttributes();

  const handleFormSubmit = (internalValues: PromptBuilderFormTypes) => {
    const usersToSendTo = users ?? [];

    if (formIsValid(internalValues)) {
      dispatch(
        setCurrentLocalPrompt({
          ...internalValues,
          userList: usersToSendTo,
          ctaValue: internalValues.ctaValue?.map((c) => ({ ...c, expanded: false })),
        })
      );

      push(`${RoutePath.PromptPreview}/local_${internalValues.id}`);
    }
  };

  const handleFormReset = () => {
    dispatch(clearLocalPrompt());
  };

  const formIsValid = (internalValues: PromptBuilderFormTypes) => {
    return !!internalValues.promptDateTime && internalValues.name && internalValues.body;
  };

  const formikProps: FormikProps<PromptBuilderFormTypes> = useFormik({
    initialValues,
    onSubmit: handleFormSubmit,
    onReset: handleFormReset,
    validationSchema,
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: false,
  });

  const {
    values,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
    errors,
    touched,
    setFieldValue,
  } = formikProps;

  const scrollErrorsIntoView = () => {
    if (errors) {
      const firstInvalidField = Object.keys(errors)[0];
      if (firstInvalidField) {
        const fieldElement = document.querySelector(`[name="${firstInvalidField}"]`);
        if (fieldElement) {
          const errorParent = fieldElement.parentElement;
          if (errorParent) {
            errorParent.scrollIntoView({ behavior: 'auto', block: 'center' });
          }
        }
      }
    }
  };

  const handleSubmitWithErrors = (e?: React.FormEvent<HTMLFormElement>) => {
    scrollErrorsIntoView();
    handleSubmit(e);
  };

  const onCsvUpload = useMemo(
    () => (rows: OrgUser[]) => {
      setUsers(rows);
      setFieldValue('userList', rows);
    },
    [setFieldValue]
  );

  const sixPtScaleCTAS = useMemo(
    () => [
      {
        name: 'Strongly agree',
        description: 'Thank you for your response.',
        id: window.crypto.randomUUID(),
        expanded: false,
      },
      {
        name: 'Agree',
        description: 'Thank you for your response.',
        id: window.crypto.randomUUID(),
        expanded: false,
      },
      {
        name: 'Neither agree nor disagree',
        description: 'Thank you for your response.',
        id: window.crypto.randomUUID(),
        expanded: false,
      },
      {
        name: 'Disagree',
        description: 'Thank you for your response.',
        id: window.crypto.randomUUID(),
        expanded: false,
      },
      {
        name: 'Strongly disagree',
        description: 'Thank you for your response.',
        id: window.crypto.randomUUID(),
        expanded: false,
      },
      {
        name: "Not applicable/I don't know",
        description: 'Thank you for your response.',
        id: window.crypto.randomUUID(),
        expanded: false,
      },
    ],
    []
  );

  useEffect(() => {
    if (values.ctaType === PromptCTATypes.sixPtScale) {
      setFieldValue('ctaValue', sixPtScaleCTAS);
      return;
    }
    if (values.ctaType === PromptCTATypes.sixPtScaleAndOpenEndedText) {
      setFieldValue(
        'ctaValue',
        sixPtScaleCTAS.map((p) => ({ ...p, expanded: true, description: '' }))
      );
      return;
    }

    setFieldValue('ctaValue', []);
  }, [setFieldValue, sixPtScaleCTAS, values.ctaType]);

  const handleTagsChange = useCallback(
    (value: SelectOrCreateOptionType[]) => {
      const newList = value.map((tagItem) => {
        if (typeof tagItem === 'string') {
          const tagFromOptions = tags.find((tag) => {
            return tag.label.toLocaleLowerCase() === tagItem.toLocaleLowerCase();
          });

          return tagFromOptions
            ? { id: tagFromOptions.id.toString(), label: tagFromOptions.label }
            : { id: tagItem, label: tagItem };
        }
        return tagItem;
      });

      setFieldValue('promptTags', newList);
    },
    [tags, setFieldValue]
  );

  return (
    <ContainerLayout>
      <Card>
        <Box className={classes.heading}>
          <Typography
            className={classes.manageNudgeCompany}
            variant="h2"
            data-testid="nudges_page_title">
            {title}
          </Typography>
          <Typography color="textSecondary">{values.id}</Typography>
        </Box>
        <form onSubmit={handleSubmitWithErrors} onReset={handleReset}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormLabelWithHelperText
                label={promptBuilder.promptName}
                description={promptBuilder.promptNameDescription}
              />
              <Input
                fullWidth
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={promptBuilder.promptName}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              direction="column"
              style={{ display: 'flex', justifyContent: 'space-between' }}>
              <FormLabelWithHelperText
                label={promptBuilder.promptGroovVoiceSwitch}
                description={promptBuilder.promptGroovVoiceSwitchDescription}
              />
              <Box height={50} display="flex" alignItems="center">
                <Typography>No</Typography>
                <Switch
                  name="isGroovVoice"
                  value={values.isGroovVoice}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={values.isGroovVoice}
                  style={{ color: !values.isGroovVoice ? colors.red[500] : undefined }}
                  classes={{
                    track: !values.isGroovVoice ? classes.switchTrack : '',
                  }}
                />
                <Typography>Yes</Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              direction="column"
              style={{ display: 'flex', justifyContent: 'space-between' }}>
              <FormLabelWithHelperText
                label={promptBuilder.promptResponseConfidentialSwitch}
                description={promptBuilder.promptResponseConfidentialSwitchDescription}
              />

              <Box height={50} display="flex" alignItems="center">
                <Typography>No</Typography>
                <Switch
                  name="isConfidential"
                  value={values.isConfidential}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={values.isConfidential}
                  style={{ color: !values.isConfidential ? colors.red[500] : undefined }}
                  classes={{
                    track: !values.isConfidential ? classes.switchTrack : '',
                  }}
                />
                <Typography>Yes</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormLabelWithHelperText
                label={promptBuilder.promptDateAndTime}
                description={promptBuilder.promptDateAndTimeDescription}
              />
              <Input
                fullWidth={true}
                name="promptDateTime"
                value={values.promptDateTime}
                onChange={handleChange}
                onBlur={handleBlur}
                type="datetime-local"
                error={touched.promptDateTime && Boolean(errors.promptDateTime)}
                helperText={touched.promptDateTime && errors.promptDateTime}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl error={touched.categoryId && Boolean(errors.categoryId)} fullWidth>
                <FormLabelWithHelperText
                  label={promptBuilder.promptCategory}
                  description={promptBuilder.promptCategoryDescription}
                />
                <Select
                  value={values.categoryId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  name="categoryId"
                  className={classes.select}
                  fullWidth
                  disabled={attributesLoading}>
                  {categoryOptions.map((o) => (
                    <MenuItem key={o.id} value={o.id}>
                      {o.label}
                    </MenuItem>
                  ))}
                </Select>
                {touched.categoryId && Boolean(errors.categoryId) && (
                  <FormHelperText>{errors.categoryId}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormLabelWithHelperText
                label={'Tags'}
                description={'Select some tags or create your own'}
              />
              <SearchAndSelect
                options={tags}
                value={values.promptTags}
                handleOnChange={(_, value) => handleTagsChange(value)}
                className={tagStyles.root}
                classes={{ inputRoot: tagStyles.inputRoot }}
                disabled={attributesLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabelWithHelperText
                label={promptBuilder.promptBody}
                description={promptBuilder.promptBodyDescription}
              />
              <Box mt={2} />
              <RichTextEditor
                value={values.body}
                onChange={handleChange}
                onBlur={handleBlur}
                name="body"
                maxHeight={300}
                error={touched.body && Boolean(errors.body)}
                helperText={touched.body ? errors.body : ''}
                maxCharacters={MAX_TEXT_LENGTH}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabelWithHelperText
                label={promptBuilder.promptCTA}
                description={promptBuilder.promptCTADescription}
              />
              <RadioGroup
                defaultValue={values.ctaType}
                name="ctaType"
                onChange={handleChange}
                onBlur={handleBlur}>
                <FormControlLabel
                  value={PromptCTATypes.custom}
                  control={<Radio />}
                  label={
                    <Box className={classes.radio}>
                      <Typography>{promptBuilder.promptCTACustom}</Typography>
                      <Typography variant="subtitle2">
                        {promptBuilder.promptCTACustomDescription}
                      </Typography>
                    </Box>
                  }
                />
                <FormControlLabel
                  value={PromptCTATypes.sixPtScale}
                  control={<Radio />}
                  label={
                    <Box className={classes.radio}>
                      <Typography>{promptBuilder.promptCTASixPointScale}</Typography>
                      <Typography variant="subtitle2">
                        {promptBuilder.promptCTASixPointScaleDescription}
                      </Typography>
                    </Box>
                  }
                />
                <FormControlLabel
                  value={PromptCTATypes.openEndedText}
                  control={<Radio />}
                  label={
                    <Box className={classes.radio}>
                      <Typography>{promptBuilder.promptCTAOpenEndedText}</Typography>
                      <Typography variant="subtitle2">
                        {promptBuilder.promptCTAOpenEndedTextDescription}
                      </Typography>
                    </Box>
                  }
                />
                <FormControlLabel
                  value={PromptCTATypes.customAndOpenEndedText}
                  control={<Radio />}
                  label={
                    <Box className={classes.radio}>
                      <Typography>{promptBuilder.promptCTACustomAndOpenEndedText}</Typography>
                      <Typography variant="subtitle2">
                        {promptBuilder.promptCTACustomAndOpenEndedTextDescription}
                      </Typography>
                    </Box>
                  }
                />
                <FormControlLabel
                  value={PromptCTATypes.sixPtScaleAndOpenEndedText}
                  control={<Radio />}
                  label={
                    <Box className={classes.radio}>
                      <Typography>
                        {promptBuilder.promptCTASixPointScaleAndOpenEndedText}
                      </Typography>
                      <Typography variant="subtitle2">
                        {promptBuilder.promptCTASixPointScaleAndOpenEndedTextDescription}
                      </Typography>
                    </Box>
                  }
                />
              </RadioGroup>
              {(values.ctaType === PromptCTATypes.custom ||
                values.ctaType === PromptCTATypes.customAndOpenEndedText) && (
                <CTASection
                  value={values.ctaValue}
                  onChange={(value: CTAType[]) => {
                    handleChange({ target: { name: 'ctaValue', value } });
                  }}
                  onBlur={handleBlur}
                  error={touched.ctaValue && Boolean(errors.ctaValue)}
                  helperText={touched.ctaValue ? (errors.ctaValue as string) : ''}
                  maxResponseTextLength={MAX_TEXT_LENGTH}
                />
              )}
              {(values.ctaType === PromptCTATypes.sixPtScale ||
                values.ctaType === PromptCTATypes.sixPtScaleAndOpenEndedText) && (
                <CTASection
                  value={values.ctaValue}
                  onChange={(value: CTAType[]) => {
                    handleChange({ target: { name: 'ctaValue', value } });
                  }}
                  onBlur={handleBlur}
                  error={touched.ctaValue && Boolean(errors.ctaValue)}
                  helperText={touched.ctaValue ? (errors.ctaValue as string) : ''}
                  partiallyEditable
                  maxResponseTextLength={MAX_TEXT_LENGTH}
                />
              )}
            </Grid>
            {(values.ctaType === PromptCTATypes.customAndOpenEndedText ||
              values.ctaType === PromptCTATypes.sixPtScaleAndOpenEndedText) && (
              <Grid item xs={12}>
                <FormLabelWithHelperText
                  label={promptBuilder.promptResponseToOpenTextAndAction}
                  description={promptBuilder.promptResponseToOpenTextAndActionDescription}
                />
                <Box mt={2} />
                <RichTextEditor
                  value={values.responseToOpenText}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="responseToOpenText"
                  maxHeight={200}
                  error={touched.responseToOpenText && Boolean(errors.responseToOpenText)}
                  helperText={touched.responseToOpenText ? errors.responseToOpenText : ''}
                  maxCharacters={MAX_TEXT_LENGTH}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <FormLabelWithHelperText
                label={promptBuilder.promptUserList}
                description={promptBuilder.promptUserListDescription}
              />
              <CsvFileUpload
                onFileChange={onCsvUpload}
                error={touched.userList && Boolean(errors.userList)}
                helperText={touched.userList ? (errors.userList as string) : ''}
              />
            </Grid>

            <Grid item xs={12} className={classes.flex} justifyContent="flex-end">
              <MUIButton
                type="reset"
                disabled={isSubmitting}
                className={classes.cancelPrompt}
                variant="text">
                {promptBuilder.promptCancel}
              </MUIButton>
              <Button type="submit" disabled={isSubmitting} className={classes.createPrompt}>
                {isSubmitting ? <AnimatedSpinner height={30} width={30} /> : submitButtonContent}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>
    </ContainerLayout>
  );
}
