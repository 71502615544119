import { AxiosRequestConfig } from 'axios';
import { ApiRequest } from 'services/request';
import { dispatch } from 'store';
import { DetailScreenFilters, setCurrentSelectedPromptDetails } from './GroovVoice.slice';
import { PromptDetails, PromptItemType, PromptUserResponse } from './GroovVoice.d';
import moment from 'moment';
import { PromptCTATypes } from 'pages/PromptBuilder';
import { GridSize } from '@material-ui/core';

export const colorPalette = [
  '#006AFF',
  '#74E4C6',
  '#8C6B8C',
  '#7558FF',
  '#B55D35',
  '#D95A96',
  '#F0CEE2',
  '#0D4027',
  '#FBAA23',
  '#EE3130',
  '#6EBFE2',
];

const BASE_SERVICE_URL = process.env.REACT_APP_SERVICE_URL;

export const getRandomColor = (index?: number): string => {
  if (index?.toString()) {
    return colorPalette[index % colorPalette.length];
  }
  return colorPalette[Math.floor(Math.random() * colorPalette.length)];
};

export const getGroovVoicePrompts = async (
  startTime: moment.Moment = getRolling30Days(),
  endTime: moment.Moment = getEndOfCurrentDay()
) => {
  const format = 'YYYY-MM-DDTHH:mm:ss';
  const startTimeUtc = startTime.utc().format(format);
  const endTimeUtc = endTime.utc().format(format);
  const config: AxiosRequestConfig = {
    baseURL: BASE_SERVICE_URL,
    url: `conversation/partner/prompts/report?startTimeUtc=${startTimeUtc}&endTimeUtc=${endTimeUtc}`,
    method: 'GET',
  };

  const result = await ApiRequest(config);
  return result;
};

export const getRolling30Days = () => {
  return moment().subtract(30, 'days').startOf('day');
};

export const getEndOfCurrentDay = () => {
  return moment().endOf('day');
};

function buildQuery(baseUrl: string, id: string, filterState: DetailScreenFilters): string {
  const queryParts: string[] = [];

  if (filterState.manager && filterState.manager.length > 0) {
    filterState.manager.forEach((manager) => {
      queryParts.push(`managerIds=${encodeURIComponent(manager.id)}`);
    });
  }

  if (filterState.officeLocation && filterState.officeLocation.length > 0) {
    filterState.officeLocation.forEach((location) => {
      queryParts.push(`officeLocations=${encodeURIComponent(location.id)}`);
    });
  }

  if (filterState.department && filterState.department.length > 0) {
    filterState.department.forEach((department) => {
      queryParts.push(`departments=${encodeURIComponent(department.id)}`);
    });
  }

  if (filterState.jobTitle && filterState.jobTitle.length > 0) {
    filterState.jobTitle.forEach((jobTitle) => {
      queryParts.push(`jobTitles=${encodeURIComponent(jobTitle.id)}`);
    });
  }

  const queryString = queryParts.length > 0 ? `?${queryParts.join('&')}` : '';
  return `${baseUrl}/${id}${queryString}`;
}
export const getAndSetCurrentSelectedPromptDetails = async (
  id: string,
  filters?: DetailScreenFilters
) => {
  let config: AxiosRequestConfig = {
    baseURL: BASE_SERVICE_URL,
    url: `conversation/partner/prompts/report/${id}`,
    method: 'GET',
  };

  if (filters) {
    const queryUrl: string = buildQuery(`conversation/partner/prompts/report`, id, filters);
    config = {
      baseURL: BASE_SERVICE_URL,
      url: queryUrl,
      method: 'GET',
    };
  }
  const result = await ApiRequest(config);
  if (result.status === 200) {
    const promptDetails = {
      prompt: result.data.prompt,
      responses: result.data.responses,
      count: result.data.count,
      filters: result.data.filters,
      totalResponses: result.data.totalResponses,
      totalFollowUps: result.data.totalFollowUps,
    };
    dispatch(setCurrentSelectedPromptDetails(promptDetails));
  }
};

export const getUniqueTags = (prompts: PromptItemType[]): string[] => {
  const allTags = prompts?.flatMap((prompt) => prompt.tags);
  return Array.from(new Set(allTags));
};

const promptTypeColors = [
  { color: '#0A2A3D', backgroundColor: '#7fbddf' },
  { color: '#3B280C', backgroundColor: '#f1ad46' },
  { color: '#3C0734', backgroundColor: '#DC98B9' },
  { color: '#3E140F', backgroundColor: '#DB9D95' },
  { color: '#2C3215', backgroundColor: '#b6ca64' },
];

export const promptTypes = [
  { id: PromptCTATypes.custom, label: 'Actions', ...promptTypeColors[0] },
  { id: PromptCTATypes.sixPtScale, label: '6pt Scale', ...promptTypeColors[1] },
  { id: PromptCTATypes.openEndedText, label: 'Open-ended', ...promptTypeColors[2] },
  {
    id: PromptCTATypes.customAndOpenEndedText,
    label: 'Actions & Follow-up',
    ...promptTypeColors[3],
  },
  {
    id: PromptCTATypes.sixPtScaleAndOpenEndedText,
    label: '6pt Scale & Follow-up',
    ...promptTypeColors[4],
  },
];

export const getPromptTags = (prompt: PromptItemType) => {
  const typeTag = promptTypes.filter(
    (type) => type.id === prompt?.properties?.prompt_cta_type
  )?.[0];

  const otherTags = prompt?.tags.map((p) => ({ id: p, label: p })) ?? [];

  if (typeTag) {
    return [typeTag, ...otherTags];
  }
  return otherTags;
};

type SixPtScaleCounts = {
  stronglyAgreeCount: number;
  agreeCount: number;
  neutralCount: number;
  disagreeCount: number;
  stronglyDisagreeCount: number;
  notApplicableCount: number;
};

// The six-point scale mean is a weighted average of the five-point scale.
// 1 = strongly agree, 2 = agree, 3 = neutral, 4 = disagree, 5 = strongly disagree
export const sixPtMean = ({
  stronglyAgreeCount,
  agreeCount,
  neutralCount,
  disagreeCount,
  stronglyDisagreeCount,
}: SixPtScaleCounts) => {
  const total =
    stronglyAgreeCount + agreeCount + neutralCount + disagreeCount + stronglyDisagreeCount;

  if (total === 0) {
    return 0;
  }

  return (
    (stronglyAgreeCount * 5 +
      agreeCount * 4 +
      neutralCount * 3 +
      disagreeCount * 2 +
      stronglyDisagreeCount * 1) /
    total
  );
};

// We use a Net Promoter Score for favourability which discounts Neutral responses.
export const sixPtNetFavourability = ({
  stronglyAgreeCount,
  agreeCount,
  disagreeCount,
  stronglyDisagreeCount,
}: SixPtScaleCounts) => {
  const netFavourablity = stronglyAgreeCount + agreeCount;
  const netUnfavourability = stronglyDisagreeCount + disagreeCount;

  const netPromoterTotal = netFavourablity + netUnfavourability;

  const favourabilityPercentage = netPromoterTotal ? (netFavourablity / netPromoterTotal) * 100 : 0;
  const unfavourabilityPercentage = netPromoterTotal
    ? (netUnfavourability / netPromoterTotal) * 100
    : 0;

  return { favourabilityPercentage, unfavourabilityPercentage };
};

export const formatNumber = (value: number) => {
  return value % 1 === 0 ? value.toString() : value.toFixed(1);
};

export const getSixPointScaleAnalyticalCalculations = (
  filteredPromptResponsesDetails: PromptUserResponse[]
) => {
  const getCountByText = (text: string) => {
    return (filteredPromptResponsesDetails ?? []).filter((c) => c.text === text).length;
  };

  const stronglyAgreeCount = getCountByText('Strongly agree');
  const agreeCount = getCountByText('Agree');
  const neutralCount = getCountByText('Neither agree nor disagree');
  const disagreeCount = getCountByText('Disagree');
  const stronglyDisagreeCount = getCountByText('Strongly disagree');
  const notApplicableCount = getCountByText('Not applicable');

  const mean = sixPtMean({
    stronglyAgreeCount,
    agreeCount,
    neutralCount,
    disagreeCount,
    stronglyDisagreeCount,
    notApplicableCount,
  });

  const { favourabilityPercentage, unfavourabilityPercentage } = sixPtNetFavourability({
    stronglyAgreeCount,
    agreeCount,
    neutralCount,
    disagreeCount,
    stronglyDisagreeCount,
    notApplicableCount,
  });
  return {
    mean,
    favourabilityPercentage,
    unfavourabilityPercentage,
  };
};

export const getAdditionalAnalyticsLayout = (
  promptType: PromptCTATypes,
  prompt: PromptItemType,
  promptDetails: PromptDetails,
  filteredPromptResponsesDetails: PromptUserResponse[]
) => {
  const { mean, favourabilityPercentage, unfavourabilityPercentage } =
    getSixPointScaleAnalyticalCalculations(filteredPromptResponsesDetails);
  const responseCount = promptDetails.totalResponses ?? 0;
  const totalSent = prompt.totalSent;
  const responseRate = totalSent ? (responseCount / totalSent) * 100 : 0;
  const openEndedResponsesCount = promptDetails.totalFollowUps ?? 0;
  const openEndedResponseRate = totalSent
    ? (openEndedResponsesCount / totalSent) * 100
    : 0;

  switch (promptType) {
    case PromptCTATypes.custom:
    case PromptCTATypes.openEndedText:
      return [
        {
          label: 'Responses',
          value: formatNumber(responseCount),
          subValue: `/${formatNumber(totalSent)}`,
          changeBackgroundOnFilter: true,
        },
        {
          label: 'Response Rate',
          value: formatNumber(responseRate),
          subValue: '%',
          changeBackgroundOnFilter: true,
        },
      ];
    case PromptCTATypes.sixPtScale:
      return [
        {
          label: 'Responses',
          value: formatNumber(responseCount),
          subValue: `/${formatNumber(totalSent)}`,
        },
        {
          label: 'Response Rate',
          value: formatNumber(responseRate),
          subValue: '%',
        },
        {
          label: 'Mean',
          value: formatNumber(mean),
          changeBackgroundOnFilter: true,
        },
        {
          label: 'Favourable',
          value: formatNumber(favourabilityPercentage),
          subValue: '%',
          changeBackgroundOnFilter: true,
        },
        {
          label: 'Unfavourable',
          value: formatNumber(unfavourabilityPercentage),
          subValue: '%',
          changeBackgroundOnFilter: true,
        },
      ];
    case PromptCTATypes.sixPtScaleAndOpenEndedText:
      return [
        {
          label: 'Responses',
          value: formatNumber(responseCount),
          subValue: `/${formatNumber(totalSent)}`,
        },
        {
          label: 'Response Rate',
          value: formatNumber(responseRate),
          subValue: '%',
        },
        {
          label: 'Follow-up',
          value: formatNumber(openEndedResponsesCount),
          subValue: `/${formatNumber(totalSent)}`,
        },
        {
          label: 'Follow-up Rate',
          value: formatNumber(openEndedResponseRate),
          subValue: '%',
        },
        {
          label: 'Mean',
          value: formatNumber(mean),
          changeBackgroundOnFilter: true,
        },
        {
          label: 'Favourable',
          value: formatNumber(favourabilityPercentage),
          subValue: '%',
          changeBackgroundOnFilter: true,
        },
        {
          label: 'Unfavourable',
          value: formatNumber(unfavourabilityPercentage),
          subValue: '%',
          changeBackgroundOnFilter: true,
        },
      ];
    case PromptCTATypes.customAndOpenEndedText:
      return [
        {
          label: 'Responses',
          value: formatNumber(responseCount),
          subValue: `/${formatNumber(totalSent)}`,
        },
        {
          label: 'Response Rate',
          value: formatNumber(responseRate),
          subValue: '%',
        },
        {
          label: 'Follow-up',
          value: formatNumber(openEndedResponsesCount),
          subValue: `/${formatNumber(totalSent)}`,
        },
        {
          label: 'Follow-up Rate',
          value: formatNumber(openEndedResponseRate),
          subValue: '%',
        },
      ];
    default:
      return [];
  }
};

export const getResponseLayout = (
  promptType?: PromptCTATypes,
  isConfidential?: boolean
): Array<{
  label: string;
  fieldName: keyof Omit<PromptUserResponse, 'id' | 'properties'>;
  gridSize: GridSize;
  type?: string;
}> => {
  switch (promptType) {
    case PromptCTATypes.custom:
    case PromptCTATypes.sixPtScale:
      if (isConfidential) {
        return [
          {
            label: 'Response',
            fieldName: 'text',
            gridSize: 6,
          },
          {
            label: 'Time',
            fieldName: 'timestamp',
            gridSize: 4,
            type: 'time',
          },
        ];
      }
      return [
        {
          label: 'User',
          fieldName: 'username',
          gridSize: 4,
        },
        {
          label: 'Response',
          fieldName: 'text',
          gridSize: 4,
        },
        {
          label: 'Time',
          fieldName: 'timestamp',
          gridSize: 4,
          type: 'time',
        },
      ];
    case PromptCTATypes.openEndedText:
      if (isConfidential) {
        return [
          {
            label: 'Response',
            fieldName: 'text',
            gridSize: 12,
          },
        ];
      }
      return [
        {
          label: 'User',
          fieldName: 'username',
          gridSize: 4,
        },
        {
          label: 'Response',
          fieldName: 'text',
          gridSize: 8,
        },
      ];
    case PromptCTATypes.customAndOpenEndedText:
    case PromptCTATypes.sixPtScaleAndOpenEndedText:
      if (isConfidential) {
        return [
          {
            label: 'Response',
            fieldName: 'text',
            gridSize: 6,
          },
          {
            label: 'Follow-up',
            fieldName: 'followUpText',
            gridSize: 6,
          },
        ];
      }
      return [
        {
          label: 'User',
          fieldName: 'username',
          gridSize: 4,
        },
        {
          label: 'Response',
          fieldName: 'text',
          gridSize: 4,
        },
        {
          label: 'Follow-up',
          fieldName: 'followUpText',
          gridSize: 4,
        },
      ];

    default:
      return [];
  }
};
